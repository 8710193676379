import type { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Image from 'next/image';
import amoebaIcon from '../public/icon.png';
import playStoreDownloadPng from '../public/play-store-download.png';

const Home: NextPage = () => {
  const router = useRouter();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (router.query.r == null) return;
      let redirectUrl = router.query.r;
      if (Array.isArray(redirectUrl)) {
        redirectUrl = redirectUrl[0];
      }
      window.location.href = redirectUrl;
    }
  }, [router.query]);

  return (
    <div className="bg-green-700 flex flex-col items-center w-screen h-screen p-6">
      <NextSeo
        title="Amoeba 28"
        description="Amoeba 28 - Explore the world of luxury"
      />
      <Image
        alt="Amoeba 28 icon"
        className="rounded-md"
        width={250}
        height={250}
        src={amoebaIcon}
      />
      <h1 className="text-white text-xl mt-32 mb-3">Amoeba 28</h1>
      <h2 className="text-white">一切奢侈品•盡在Amoeba</h2>
      <h3 className="text-white text-xs">Amoeba • Your Luxury Marketplace</h3>
      <div className="flex items-center mt-7">
        <Image
          alt="app store download icon"
          src="/app-store-download.svg"
          width={120}
          height={80}
          onClick={() => {
            if (window) {
              window.location.href =
                'https://apps.apple.com/app/amoeba-28/id1630974085';
            }
          }}
        />
        <Image
          alt="play store download icon"
          src={playStoreDownloadPng}
          width={150}
          onClick={() => {
            if (window) {
              window.location.href =
                'https://play.google.com/store/apps/details?id=com.amoeba28.dev&hl=zh-hk';
            }
          }}
        />
      </div>
    </div>
  );
};

export default Home;
